// Page 404
// Render 404 view
import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Jumbotron from '../components/Jumbotron'
import { Container } from 'reactstrap'

const NotFoundPage = () => (
  <Layout pageTitle="404 Not Found">
    <SEO 
   	  title="404: Not found" 
      lang="id"
      img="https://firebasestorage.googleapis.com/v0/b/waingapu-open-mind.appspot.com/o/thumbnail%2Fpage-with-404-error-for-website-cartoon-style-vector-9488679.jpg?alt=media&token=733f7382-056d-442e-b077-5b4aebf974f4"
      description="Halaman Tidak Ditemukan"      
    />
    <Jumbotron title="404 Waingapu Open Mind" info="Halaman yang ada cari tidak ditemukan, silakan kembali ke halaman utama." />
    <Container className="text-center">
    	<h1>
    		Halaman Tidak Ditemukan, 404
    	</h1>
	    <Link className="btn btn-primary text-uppercase" to={'/'} >Kembali</Link> 
	    <br/>
	    <br/>
    </Container>
  </Layout>
)

export default NotFoundPage
